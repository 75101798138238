import { ServiceCallError } from 'api/ServiceCallError';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as WidgetParameterTemplate from 'soy/perspectives/dashboard/widgets/parameters/WidgetParameterTemplate.soy.generated';
import * as WidgetTemplate from 'soy/perspectives/dashboard/widgets/WidgetTemplate.soy.generated';
import * as array from 'ts-closure-library/lib/array/array';
import * as asserts from 'ts-closure-library/lib/asserts/asserts';
import * as dom from 'ts-closure-library/lib/dom/dom';
import { Size } from 'ts-closure-library/lib/math/size';
import { Tooltip } from 'ts-closure-library/lib/ui/tooltip';
import { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import * as soy from 'ts/base/soy/SoyRenderer';
import type { MetricGroup } from 'ts/commons/dialog/HierarchicMetricThresholdSelectionDialog';
import { MetricsUtils } from 'ts/commons/MetricsUtils';
import { PromiseReject } from 'ts/commons/PromiseReject';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import { tsdom } from 'ts/commons/tsdom';
import { UIUtils } from 'ts/commons/UIUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import type { UniformPathLikeWithLabelAndMetric } from 'ts/perspectives/dashboard/widgets/parameters/ProjectsPathsParameter';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import { BooleanParameter } from './parameters/BooleanParameter';
import type { ParameterLookup } from './parameters/ParameterLookup';
import type { ProjectPathParameterValue } from './parameters/ProjectPathParameter';
import { ProjectPathParameter } from './parameters/ProjectPathParameter';
import type { SingleMetricParameter } from './parameters/SingleMetricParameter';
import { TitleParameter } from './parameters/TitleParameter';
import type { WidgetParameterBase } from './parameters/WidgetParameterBase';
import type { WidgetEditingContext } from './WidgetEditingContext';
import { WidgetUtils } from './WidgetUtils';

export type WidgetParameterValues =
	| ProjectPathParameterValue
	| string[]
	| Record<string, unknown>
	| number
	| string
	| boolean
	| MetricGroup[]
	| UniformPathLikeWithLabelAndMetric[];

/** Base class for dashboard widgets. */
export class WidgetBase {
	/** Error state CSS class */
	public static ERROR_STATE_CSS_CLASS = 'error-state';

	/** The title parameter. */
	public static TITLE_PARAMETER: TitleParameter = new TitleParameter('Title', 'The title of the element');

	/** Parameter name of a parameter that refers to a plain project ID. */
	public static readonly PROJECT_PARAMETER_NAME = 'Project';

	/** Parameter name that stores multiple paths. */
	public static readonly PROJECT_PATHS_PARAMETER_NAME = 'Paths';

	/** Parameter name that stores multiple project and path combinations. */
	public static readonly STORED_ADDITIONAL_PATHS_PARAMETER_NAME = 'Additional paths';

	/** Parameter for both project and path. This is not used in this class, but many sub-classes share this parameter. */
	public static PROJECT_PATH_PARAMETER = new ProjectPathParameter(
		'Path',
		'The project and path for which to display data.'
	);

	/** Parameter whether to abbreviate large metric values (if shown). */
	public static ABBREVIATE_VALUES_PARAMETER = new BooleanParameter(
		'Abbreviate values',
		'Whether to abbreviate large values.'
	);

	/** Parameter whether to abbreviate large metric values (if shown). */
	public static VALUE_IS_BYTES_PARAMETER = new BooleanParameter(
		'Value is bytes',
		'Whether the value should be interpreted as bytes. For abbreviation, this means that we divide by 1024 instead of 1000.'
	);

	/** The service client. We need our own client, as we use a separate error handler. */
	public client: TeamscaleServiceClient;

	/** Flag for remembering if any errors occurred. */
	private hadErrors = false;

	/** Flag for remembering if any warnings occurred. */
	private hadWarnings = false;

	/** Array of warnings. */
	private warnings: string[] = [];

	/** The project referenced by a widget instance. Changes during widget editing operations. */
	protected referencedProject: string | null = null;

	protected timeContext: TimeContext | null = null;

	/**
	 * Stores whether the widget is currently in 'quick-edit' mode. Needed to determine if the dashboard should be saved
	 * as soon as 'Ok' was clicked in the widget properties dialog.
	 */
	public inQuickEditMode = false;

	/** Flag indicates whether trend setting parameter is ignored. */
	private trendIgnored = false;

	/** This widget's descriptor */
	protected descriptor: WidgetDescriptor | null = null;

	/**
	 * The projects the current user has access to. Can be accessed via {@link #getProjects} after {@link #render} was
	 * called.
	 */
	private projects: ExtendedProjectInfo[] | null = null;

	private widgetContext: WidgetEditingContext | null = null;

	/** The current (extended) perspective context. */
	protected perspectiveContext: ExtendedPerspectiveContext;

	/** @param containerElement The element to render the widget into. */
	public constructor(protected containerElement: HTMLElement, perspectiveContext: ExtendedPerspectiveContext) {
		this.client = new TeamscaleServiceClient(error => this.handleLoadingError(error));
		this.perspectiveContext = perspectiveContext;
	}

	/** Returns whether errors occurred. */
	public errorsOccurred(): boolean {
		return this.hadErrors;
	}

	/** Returns the parameters supported by this widget. */
	public getParameters(): WidgetParameterBase[] {
		return [WidgetBase.TITLE_PARAMETER];
	}

	/** Returns a default value for the given parameter. */
	public provideParameterDefault(
		parameter: WidgetParameterBase,
		context: WidgetEditingContext
	): WidgetParameterValues {
		if (parameter === WidgetBase.TITLE_PARAMETER) {
			return WidgetUtils.DEFAULT_WIDGET_TITLE;
		}

		// This is not used in this class, but many sub-classes share this
		// parameter.
		if (parameter === WidgetBase.PROJECT_PATH_PARAMETER) {
			return this.getDefaultProjectAndPath(context);
		}
		if (parameter === WidgetBase.ABBREVIATE_VALUES_PARAMETER) {
			return true;
		}
		if (parameter === WidgetBase.VALUE_IS_BYTES_PARAMETER) {
			return false;
		}
		asserts.fail('No default value implemented for parameter ' + parameter.getName() + '!');
	}

	/** Returns the default project and path to use. */
	protected getDefaultProjectAndPath(context: WidgetEditingContext): ProjectPathParameterValue {
		let project = 'unknown';
		if (context.preferredProject) {
			project = context.preferredProject;
		} else if (context.projects[0]) {
			project = context.projects[0].primaryId!;
		}
		return {
			project,
			path: '',
			hiddenInWidgetTitle: false,
			isArchitecture: false
		};
	}

	/** Loads the pre-loaded CODE metric schema for the given project and type. */
	protected getCodeMetricsSchema(projectId: string): MetricDirectorySchema {
		return this.widgetContext!.getCodeMetricsSchema(projectId);
	}

	/**
	 * Loads the (potentially pre-preloaded) metric schema for the given project and type. For the default 'CODE'
	 * metrics schema, use {@link #getCodeMetricsSchema} instead.
	 */
	protected async getMetricSchema(projectId: string, path: string): Promise<MetricDirectorySchema> {
		const type = new UniformPath(path).type;
		return this.widgetContext!.getMetricsSchema(projectId, this.client, type);
	}

	/**
	 * In order to correctly inform a user why data is not available for a widget to display graphical output, this
	 * function determines if its because of on-going (re-)analysis of this widget's referenced project or even all
	 * projects.
	 */
	protected async notifyDataUnavailable(): Promise<void> {
		const state = await this.client.getProjectsState();
		const found =
			(this.referencedProject == null && state.projects.length === 0 && state.initialProjects.length > 0) ||
			(this.referencedProject != null && state.initialProjects.includes(this.referencedProject));
		dom.removeChildren(this.containerElement);
		this.removeWidgetLoadingElement();
		this.containerElement.appendChild(WidgetUtils.renderNoDataAvailableElement(found));
	}

	/** Show a simple message as widget content. */
	public showMessage(message: string, withProjectLink = false, project = ''): void {
		dom.removeChildren(asserts.assertElement(this.containerElement));
		this.removeWidgetLoadingElement();
		let widgetElement = soy.renderAsElement(WidgetTemplate.widgetInfoText, { message });
		if (withProjectLink) {
			widgetElement = soy.renderAsElement(WidgetTemplate.widgetInfoTextWithProjectLink, {
				project,
				message
			});
		}
		this.containerElement.appendChild(widgetElement);
	}

	/** Renders the element into the container element. */
	public async render(
		parameterLookup: ParameterLookup,
		commit: UnresolvedCommitDescriptor | null,
		projects: ExtendedProjectInfo[]
	): Promise<void> {
		this.projects = projects;
		this.referencedProject = this.getProject(parameterLookup);
		if (this.referencedProject !== 'unknown') {
			this.checkForProjectReferencedByInternalId(this.referencedProject);
		}
		this.timeContext = new TimeContext(this.client, commit);
		this.dispose();
		this.containerElement.appendChild(soy.renderAsElement(WidgetTemplate.loading));
		this.hadErrors = false;
		this.hadWarnings = false;
		this.warnings = [];
		await this.preloadDataAsync(parameterLookup).catch(error => this.handleLoadingError(error));
		this.refreshContent(parameterLookup);
	}

	/** Returns the user-visible projects. Will throw an error if called before {@link render}. */
	protected getProjects(): ExtendedProjectInfo[] {
		if (this.projects == null) {
			throw new Error('Projects were not initialized, has render() been called yet?');
		}
		return this.projects;
	}

	/**
	 * Helper method that returns the primary ids of all of {@link #getProjects}. Only available after {@link render} has
	 * been called.
	 */
	protected getPrimaryProjectIds(): string[] {
		return this.getProjects().map(projectInfo => projectInfo.primaryId);
	}

	/** Cleans up any side-effect from having the widget in the DOM. */
	public dispose(): void {
		dom.removeChildren(this.containerElement);
	}

	/**
	 * Loads the data needed to render the widgets. Any errors that occur (rejected promises and thrown errors) will be
	 * caught by this class and lead to a widget with a error message. It is also possible to specifically handle errors
	 * on a per-request basis using {@link Promise#catch}.
	 */
	public async preloadDataAsync(parameterLookup: ParameterLookup): Promise<void> {
		// Default implementation does nothing
		return Promise.resolve();
	}

	/** Remove the widget-loading element from a widget. */
	public removeWidgetLoadingElement(): void {
		tsdom.removeNode(dom.getElementByClass('widget-loading', this.containerElement));
	}

	/**
	 * Rerenders the content based on previously loaded data.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	public refreshContent(parameterLookup: ParameterLookup): void {
		// No refresh in case of errors to not hide the error message
		if (this.hadErrors) {
			this.removeWidgetLoadingElement();
			return;
		}
		this.dispose();
		if (this.hadWarnings) {
			this.appendWarningSignWithTooltips(this.warnings);
		}
		this.renderContent(parameterLookup);
	}

	/** Appends a warning sign and its tooltips to the widget. */
	public appendWarningSignWithTooltips(warnings: string[]): void {
		const warningElement = soy.renderAsElement(WidgetTemplate.warning);
		dom.insertSiblingAfter(warningElement, this.getAnalysisStateMarkerElement());
		const tooltipContent = UIUtils.renderAsSafeHtml(WidgetTemplate.warningsTooltipText, { warnings });
		new Tooltip(warningElement).setSafeHtml(tooltipContent);
	}

	/** Returns the analysis-state-marker element inside the widget's title. */
	private getAnalysisStateMarkerElement(): Element | null {
		const titleElement = this.getTitleElement();
		if (titleElement == null) {
			return null;
		}
		return dom.getElementByClass('analysis-state-marker', titleElement);
	}

	public getTitleElement(): Element | null {
		// We need the parent of the container element here, because the latter only contains the widget content (not
		// the title)
		return dom.getElementByClass('widget-title', this.containerElement.parentElement);
	}

	/**
	 * Sets {@link
	 *
	 * # inQuickEditMode}, and also shows/hides the quick edit style of the widget's
	 *
	 * Title.
	 */
	public setIsInQuickEditMode(enable: boolean): void {
		this.inQuickEditMode = enable;
		this.getTitleElement()!.classList.toggle('quick', this.inQuickEditMode);
		this.getTitleElement()!.classList.toggle('editing', this.inQuickEditMode);
	}

	/**
	 * Template method for rendering the content into the container element. This is called after all data has been
	 * loaded.
	 */
	public renderContent(parameterLookup: ParameterLookup): void {
		this.containerElement.appendChild(soy.renderAsElement(WidgetTemplate.defaultImplementation));
	}

	/** Function used to report errors. */
	public error(message: string): void {
		this.hadErrors = true;
		this.containerElement.appendChild(soy.renderAsElement(WidgetTemplate.error, { message }));
	}

	/**
	 * Handler method for showing errors. Handles PromiseRejects and ServiceCallErrors gracefully and re-throws other
	 * errors (which shouldn't occur here).
	 */
	protected handleLoadingError(error: unknown): void {
		this.removeWidgetLoadingElement();
		if (error instanceof PromiseReject) {
			this.error(error.toString());
			return;
		}
		if (error instanceof ServiceCallError) {
			this.error(error.message);
			return;
		}
		throw error;
	}

	/** Function used to report warnings. */
	public warning(message: string): void {
		this.hadWarnings = true;
		this.warnings.push(message);
	}

	/**
	 * Returns the project selected or 'unknown' if the parameterLookup is not a function or project not found.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	protected getProject(parameterLookup: ParameterLookup): string {
		const projectAndPath = parameterLookup(
			WidgetBase.PROJECT_PATH_PARAMETER.getName()
		) as ProjectPathParameterValue | null;
		if (projectAndPath != null) {
			return projectAndPath.project;
		}
		return 'unknown';
	}

	/**
	 * Checks if this widget references a project by its internal id, which is discouraged.
	 *
	 * @param projectId The project reference in the widget
	 */
	private checkForProjectReferencedByInternalId(projectId: string): void {
		for (const projectInfo of this.getProjects()) {
			if (projectInfo.internalId === projectId) {
				this.error(
					'The path setting of this widget refers to a project by its internal ID, please use the public ID (' +
						projectInfo.publicIds[0]! +
						') instead'
				);
			}
		}
	}

	/**
	 * Returns the path selected.
	 *
	 * @param parameterLookup Function used to lookup parameter values.
	 */
	protected getPathInfo(parameterLookup: ParameterLookup): ProjectPathParameterValue {
		return parameterLookup(WidgetBase.PROJECT_PATH_PARAMETER.getName()) as ProjectPathParameterValue;
	}

	/**
	 * Retrieves the user-set value for the given parameter as the index in metricsSchema.
	 *
	 * @param paramNotSetError The error message to show, if the value is not set. Function for parameter lookup.
	 * @returns The metric index or null.
	 */
	public getMetricParameterIndex(
		param: SingleMetricParameter,
		paramNotSetError: string,
		parameterLookup: ParameterLookup,
		metricsSchema: MetricDirectorySchema
	): number | null {
		const metricName = parameterLookup(param.getName()) as string | null;
		if (metricName == null) {
			this.error(paramNotSetError);
			return null;
		}
		const metricIndex = MetricsUtils.getMetricIndex(metricName, metricsSchema);
		if (metricIndex === null) {
			this.error("Metric doesn't exist in project: " + metricName);
		}
		return metricIndex;
	}

	/** @returns The default width and height in the layout grid. */
	public getDefaultSize(): Size {
		return new Size(4, 3);
	}

	/** @returns The minimal width and height in the layout grid. */
	public getMinSize(): Size {
		return new Size(2, 2);
	}

	/** This is called after the widget has been resized to trigger any rendering required to update the display. */
	public handleResize(): void {
		// Default implementation does nothing
	}

	/**
	 * Returns the metric indexes of the given names. This also performs error handling. In case of errors, these are
	 * reported and the method returns null. In case of an empty list of metric names, all metrics from the schema are
	 * returned.
	 */
	public getMetricIndexesFromMetricNames(
		metricNames: string[] | null,
		metricsSchema: MetricDirectorySchema
	): number[] | null {
		if (!metricNames || metricNames.length === 0) {
			metricNames = MetricsUtils.getAllMetricNames(metricsSchema);
		}
		const missingMetrics: string[] = [];
		const metricsIndexes: number[] = [];
		for (let i = 0; i < metricNames.length; i++) {
			const index = MetricsUtils.getMetricIndex(metricNames[i]!, metricsSchema);
			if (index == null) {
				missingMetrics.push(metricNames[i]!);
				continue;
			}
			metricsIndexes.push(index);
		}
		if (!array.isEmpty(missingMetrics)) {
			this.error("Metrics '" + missingMetrics.join("', '") + "' do not exist in project.");
			return null;
		}
		return metricsIndexes;
	}

	/**
	 * Validates a given list of parameters and sets an error message to any invalid parameter. Intended to be
	 * overridden by subclasses.
	 *
	 *     The available parameters stored in a map, accessible by their respective name.
	 */
	public validate(parameterMap: Record<string, WidgetParameterBase>): void {
		//Default does nothing.
	}

	/**
	 * This method is called once after the rendering of the widget was completed. It can e.g. be used to show/hide
	 * parameters depending on the setting of another parameter.
	 */
	public runPostRenderActions(): void {
		// Default does nothing.
	}

	/**
	 * This method is called as soon a parameter has been changed. Note that for this to work, the WidgetParameterBase
	 *
	 * # appendChangeListener of the parameter has be be
	 *
	 * Implemented!
	 *
	 * @param parameter The parameter that was changed.
	 */
	public onParameterChanged(parameter: WidgetParameterBase): void {
		// Default does nothing.
	}

	/** Removes all error messages from all parameters of this widget, an enables the 'Ok' button. */
	public resetParameterErrorsAndButton(): void {
		const parameterContainers = tsdom.getElementsByClass('parameter-container-marker', this.getDialogContainer());
		parameterContainers.forEach(container => {
			this.removeErrorMessagesFromParameter(asserts.assertObject(container));
			container.classList.remove(WidgetBase.ERROR_STATE_CSS_CLASS);
		});
		this.setOkButtonEnabled(true);
	}

	/**
	 * Adds the given error message to the given parameters.
	 *
	 * @param parameters The parameters.
	 * @param message The error message.
	 */
	public addParameterError(parameters: WidgetParameterBase[], message: string): void {
		this.setOkButtonEnabled(false);
		parameters.forEach(parameter => {
			const container = parameter.getContainer()!;
			container.classList.add(WidgetBase.ERROR_STATE_CSS_CLASS);
			this.removeErrorMessagesFromParameter(container);
			container.appendChild(soy.renderAsElement(WidgetParameterTemplate.compactError, { message }));
		});
	}

	/** Removes (potential) error messages from the given parameter container. */
	public removeErrorMessagesFromParameter(parameterContainer: Element): void {
		const existingErrorElement = dom.getElementByClass('error-compact', parameterContainer);
		if (existingErrorElement) {
			tsdom.removeNode(existingErrorElement);
		}
	}

	/** Enables or disables the 'Ok' button of the parameter settings. */
	public setOkButtonEnabled(enable: boolean): void {
		const okButton = dom.getElementByClass(
			'goog-buttonset-default',
			this.getDialogContainer()
		) as HTMLButtonElement;
		okButton.disabled = !enable;
	}

	/** @returns The container element of the parameter dialog. */
	public getDialogContainer(): Element | null {
		return dom.getElementByClass('modal-dialog');
	}

	/** @returns The container to render the widget into. */
	public getContainerElement(): Element {
		return this.containerElement;
	}

	public isInQuickEditMode(): boolean {
		return this.inQuickEditMode;
	}

	/** Ignores trend setting, add warning describing the cause. */
	public ignoreTrendWithWarning(message: string): void {
		this.trendIgnored = true;
		this.warning(message);
	}

	/** Determines whether delta should be shown. */
	protected shouldShowDelta(trendTime: TypedPointInTime | null): boolean {
		return TimeUtils.isTrend(trendTime) && !this.trendIgnored;
	}

	/** Sets this widget's descriptor and context. This has to be done before calling {@link render} */
	public setDescriptorAndContext(descriptor: WidgetDescriptor | null, context: WidgetEditingContext): void {
		this.descriptor = descriptor;
		this.widgetContext = context;
	}
}
