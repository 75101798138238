import { Button, Icon, Message } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { useBaselines } from 'ts/base/services/BaselineServices';
import { DateUtils } from 'ts/commons/DateUtils';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';

/** Props for TimeTravelButton. */
type TimeTravelButtonProps = {
	contentName: string;
	timestamp: number | null;
	until: boolean;
	commitDeleted: CommitDescriptor | null;
};

/** Props for TimeTravelButton with jump and clear events. */
export type TimeTravelButtonPropsWithEvents = TimeTravelButtonProps & {
	onJump: () => Promise<void>;
	onClear: Callback<void>;
};

export function TimeTravelButton({
	contentName,
	timestamp,
	until,
	commitDeleted,
	onJump,
	onClear
}: TimeTravelButtonPropsWithEvents): JSX.Element | null {
	const project = useProjectIfExists();
	const baselines = useBaselines(project !== null ? [project.primaryId] : null);
	const storedBaseline = getStoredBaselineIfValid(project, baselines.baselines, timestamp);

	return (
		<div id="timetravel-item" className="item">
			{timestamp ? (
				<Message
					warning
					id="jump-to-time-button"
					style={{ minWidth: '80px !important' }}
					className="floating-width button"
					onClick={() => {
						void onJump();
					}}
					onDismiss={event => {
						onClear();
						event.stopPropagation();
					}}
				>
					<TimeTravelIcon />
					{contentName} {until ? 'until' : 'at'}{' '}
					{storedBaseline !== undefined ? storedBaseline : DateUtils.formatTimestamp(timestamp)}
					{commitDeleted != null
						? `, deleted on ${DateUtils.formatTimestamp(commitDeleted.timestamp)}`
						: null}
				</Message>
			) : (
				<Button
					secondary
					id="jump-to-time-button"
					title="Travel in time, i.e. see how the content looked in the past"
					onClick={() => void onJump()}
				>
					<TimeTravelIcon />
					{until ? `All ${contentName}` : `Latest ${contentName}`}
				</Button>
			)}
		</div>
	);
}

/**
 * Returns the baseline that was last stored in the local storage, if it is still valid (meaning the currently selected
 * timestamp still matches).
 */
function getStoredBaselineIfValid(
	project: ExtendedProjectInfo | null,
	baselines: ProjectSpecificBaselineInfo[] | undefined,
	timestamp: number | null
): string | undefined {
	if (project === null || baselines === undefined) {
		return undefined;
	}

	let storedBaseline = TimetravelUtils.getLastSelectedBaselineFromStorage();

	const matchedBaselineInfo = baselines.find(baseline => baseline.name === storedBaseline?.name);
	if (timestamp !== matchedBaselineInfo?.timestamp) {
		// The stored baseline is not corresponding to the selected timestamp anymore ==> invalidate baseline
		storedBaseline = undefined;
	}

	return storedBaseline?.name;
}

function TimeTravelIcon(): JSX.Element | null {
	return <Icon name="wait" />;
}
