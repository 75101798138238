import type { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import { UIUtils } from 'ts/commons/UIUtils';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/** Selection of utility methods to validate the right project. */
export class ProjectResolver {
	/** The key for the local storage which contains the name of the project for the current browser session. */
	private static readonly CURRENT_PROJECT_KEY = 'current-project';
	/** The list of projects */
	private readonly projects: string[];

	/** List of projects in initial analysis. */
	private readonly initialProjects: string[];

	public constructor(private readonly context: ExtendedPerspectiveContext) {
		this.projects = this.context.projectsInfo.projects;
		this.initialProjects = this.context.projectsInfo.initialProjects;
	}

	/** Return project for base perspective. */
	public resolveProjectFromHashOrLocalStorage(hash: NavigationHash): string | null {
		// Try loading the project from the navigation hash.
		const projectId: string | null = hash.getProject();

		// If no projectId is set, take the project from local storage and validate it.
		if (!projectId) {
			const projectFromLocalStorage = ProjectResolver.getProjectFromLocalStorage();
			if (this.context.projectExists(projectFromLocalStorage)) {
				return projectFromLocalStorage;
			}
			// If the project from localstorage is invalid, remove it from localstorage and return the default project
			ProjectResolver.setCurrentProject(null);
			return this.getDefaultProject();
		}

		return projectId;
	}

	/** Return project for dashboard perspective. */
	public getProjectForDashboardPerspective(hash: NavigationHash): string | null {
		const dashboardName = hash.getId();
		let projectId: string | null = hash.getProject();

		if (StringUtils.isEmptyOrWhitespace(projectId) && StringUtils.isEmptyOrWhitespace(dashboardName)) {
			const projectFromLocalStorage = ProjectResolver.getProjectFromLocalStorage();
			if (projectFromLocalStorage && this.isValidProjectForDashboardPerspective(projectFromLocalStorage)) {
				return projectFromLocalStorage;
			}
			ProjectResolver.setCurrentProject(null);
			return null;
		}
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			projectId = null;
		}

		return projectId;
	}

	/**
	 * Determines the default project.
	 *
	 * @returns The project or null if no project exist.
	 */
	private getDefaultProject(): string | null {
		if (this.projects.length > 0) {
			return this.projects[0]!;
		} else if (this.initialProjects.length > 0) {
			return this.initialProjects[0]!;
		}
		return null;
	}

	/** Returns true for the dashboard perspective if project exist in the current project list */
	private isValidProjectForDashboardPerspective(projectId: string): boolean {
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			return true;
		}
		return this.context.projectExists(projectId);
	}

	/**
	 * Gets the id of the current project from the browser local storage.
	 *
	 * @returns The project id of the current project.
	 */
	public static getProjectFromLocalStorage(): string | null {
		const localStorage = UIUtils.getLocalStorage();
		return (localStorage.get(ProjectResolver.CURRENT_PROJECT_KEY) as string) || null;
	}

	/**
	 * Stores the project id in the current-project field in the browsers local storage. If the projectId is null the
	 * current project name will be removed.
	 *
	 * @param projectId The id of the project to store.
	 */
	public static setCurrentProject(projectId: string | null): void {
		const localStorage = UIUtils.getLocalStorage();
		if (projectId) {
			localStorage.set(ProjectResolver.CURRENT_PROJECT_KEY, projectId);
		} else {
			localStorage.remove(ProjectResolver.CURRENT_PROJECT_KEY);
		}
	}
}
