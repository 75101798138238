import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as WidgetTemplate from 'soy/perspectives/dashboard/widgets/WidgetTemplate.soy.generated';
import * as soy from 'ts/base/soy/SoyRenderer';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { ColorUtils } from 'ts/commons/ColorUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import { TimeContext } from 'ts/commons/time/TimeContext';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { ProjectPathParameterValue } from 'ts/perspectives/dashboard/widgets/parameters/ProjectPathParameter';
import type { UniformPathLikeWithLabelAndMetric } from 'ts/perspectives/dashboard/widgets/parameters/ProjectsPathsParameter';
import { WidgetBase } from 'ts/perspectives/dashboard/widgets/WidgetBase';
import type { WidgetDescriptor } from 'ts/perspectives/dashboard/widgets/WidgetFactory';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';

/** Utility code for dealing with sundry needs of widgets. */
export class WidgetUtils {
	public static DEFAULT_WIDGET_TITLE = 'Widget';

	/**
	 * Renders and returns a HTML-DIV template informing the user at a point that no data is available for a given
	 * widget.
	 *
	 * @param analysisInProgress True if analysis of projects is in progress. This will help to inform users to wait
	 *   until analysis is over before viewing widget data.
	 */
	public static renderNoDataAvailableElement(analysisInProgress: boolean): Element {
		return soy.renderAsElement(WidgetTemplate.noDataAvailable, { analysisInProgress });
	}

	/** Returns all projects that are used by widgets in the given dashboard. */
	public static getProjects(dashboardDescriptor: DashboardDescriptor): string[] {
		const description = dashboardDescriptor.descriptor as {
			widgets: Array<WidgetDescriptor | null>;
		} | null;
		if (description == null) {
			return [];
		}
		const projects = new Set<string>();
		const widgetDescriptors = description.widgets;
		widgetDescriptors.forEach(widgetDescriptor => {
			// Null check necessary in case a widget has been deleted (i.e. widgetDescriptor == null)
			if (widgetDescriptor == null) {
				return;
			}
			for (const project of this.extractProjectsFromWidget(widgetDescriptor)) {
				projects.add(project);
			}
		});
		return [...projects];
	}

	private static extractProjectsFromWidget(widgetDescriptor: WidgetDescriptor): string[] {
		const path = widgetDescriptor[WidgetBase.PROJECT_PATH_PARAMETER.name] as ProjectPathParameterValue | undefined;
		if (path?.project != null) {
			return [path.project];
		}
		const project = widgetDescriptor[WidgetBase.PROJECT_PARAMETER_NAME] as string | undefined;
		if (project != null) {
			return [project];
		}
		const additionalPaths = widgetDescriptor[WidgetBase.STORED_ADDITIONAL_PATHS_PARAMETER_NAME] as
			| UniformPathLikeWithLabelAndMetric[]
			| undefined;
		if (additionalPaths != null) {
			return additionalPaths.map(additionalPath => additionalPath.project);
		}
		const projectPaths = widgetDescriptor[WidgetBase.PROJECT_PATHS_PARAMETER_NAME] as
			| UniformPathLikeWithLabelAndMetric[]
			| undefined;
		if (projectPaths != null) {
			return projectPaths.map(storedTestQuery => storedTestQuery.project);
		}
		return [];
	}

	/**
	 * Determines the color to be used.
	 *
	 * @param value The metric value
	 */
	public static determineColor(metricValue: number, colors: string[], boundaries: string): string {
		if (StringUtils.isEmptyOrWhitespace(boundaries)) {
			return ColorUtils.LIGHT_GRAY;
		}
		const split = boundaries.split(',');
		let i = 0;
		for (; i < split.length; ++i) {
			if (metricValue <= parseFloat(split[i]!)) {
				break;
			}
		}
		if (i < colors.length) {
			return colors[i]!;
		}
		return ColorUtils.LIGHT_GRAY;
	}

	/**
	 * Calculates a point in time relative to the given time context. Returns start commit if time travel point in time
	 * was before baseline.
	 */
	public static calculateRelativePointInTime(
		startPointInTime: TypedPointInTime | null,
		timeContext: TimeContext,
		widget: WidgetBase,
		errorText: string
	): Promise<UnresolvedCommitDescriptor> {
		let resolveStartCommit;
		if (!TimeUtils.isTrend(startPointInTime)) {
			resolveStartCommit = Promise.resolve(null);
		} else {
			resolveStartCommit = timeContext.resolveCommit(startPointInTime);
		}
		return resolveStartCommit
			.then(startCommit => {
				if (startCommit && !timeContext.isBeforeTimeTravel(startCommit)) {
					widget.ignoreTrendWithWarning(
						'The ' +
							errorText +
							' reference point in time is after the currently configured time travel point in time, ' +
							errorText +
							' setting is thus ignored.'
					);

					// Returns null indicating first commit.
					return null;
				}
				return startCommit;
			})
			.then(nullableCommit => TimeContext.ensureStartTimestamp(nullableCommit));
	}

	/** Returns all projects used in the widgets of the dashboard which are visible to the current user. */
	public static getVisibleProjectsUsedInWidgets(
		dashboardDescriptor: DashboardDescriptor,
		perspectiveContext: ExtendedPerspectiveContext
	): string[] {
		const projectsUsedInWidgets = WidgetUtils.getProjects(dashboardDescriptor);
		const visibleProjects = perspectiveContext.getAllProjects();
		return ArrayUtils.intersection(projectsUsedInWidgets, visibleProjects);
	}
}
