import * as events from 'ts-closure-library/lib/events/eventhandler';
import { EventType } from 'ts-closure-library/lib/ui/ac/autocomplete';
import { Remote } from 'ts-closure-library/lib/ui/ac/remote';
import { SpinnerAutoCompleteMatcher } from 'ts/commons/SpinnerAutoCompleteMatcher';

/** Provides a remote auto-complete search field. */
export class RemoteAutoComplete extends Remote {
	public constructor(element: Element, autoCompleteUrl: string) {
		const matcher = new SpinnerAutoCompleteMatcher(element.parentElement!, autoCompleteUrl, true);
		super(autoCompleteUrl, element, undefined, undefined, matcher);
		matcher.setRenderer(this.renderer_!);
		this.setHeaders({ Accept: 'application/json' });
		this.registerEvents(element);
	}

	/**
	 * Registers the necessary events to start a search with autocompletion.
	 *
	 * @param element The element to which this search bar should be appended.
	 */
	private registerEvents(element: Element): void {
		// Workaround to ensure the spinner gets started and stopped correctly.
		const parent = element.parentElement!;
		events.listen(
			this,
			EventType.SUGGESTIONS_UPDATE,
			function () {
				parent.classList.remove(SpinnerAutoCompleteMatcher.SPINNER_CLASS);
			},
			false,
			this
		);
	}
}
