import { RemoteArrayMatcher } from 'ts-closure-library/lib/ui/ac/remotearraymatcher';
import type { Renderer } from 'ts-closure-library/lib/ui/ac/renderer';

/** A {@link RemoteArrayMatcher} that shows a loading spinner. */
export class SpinnerAutoCompleteMatcher extends RemoteArrayMatcher {
	private readonly element: Element;

	private renderer: Renderer | null = null;

	/** Class annotation to show the loading spinner. */
	public static SPINNER_CLASS = 'loading';

	/** The passed element will get the {@ink SPINNER_CLASS} during loading. */
	public constructor(element: Element, url: string, noSimilar?: boolean) {
		super(url, noSimilar);
		this.element = element;
	}

	public setRenderer(renderer: Renderer): void {
		this.renderer = renderer;
	}

	public override requestMatchingRows(
		token: string,
		maxMatches: number,
		matchHandler: (token: string, matches: string[]) => void,
		fullString?: string
	): void {
		super.requestMatchingRows(token, maxMatches, matchHandler, fullString);
		this.element.classList.add(SpinnerAutoCompleteMatcher.SPINNER_CLASS);
		if (this.renderer) {
			this.renderer.reposition();
		}
	}
}
