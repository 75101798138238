// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/perspectives/tests/TestsTemplate.soy

/**
 * @fileoverview Templates in namespace ts.perspectives.tests.TestsTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';
import * as $soy$ts$commons$UIUtilsTemplate from './../../commons/UIUtilsTemplate.soy.generated.js';


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $selectorContainer = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.selectorContainer']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.selectorContainer'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui secondary menu selector-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.selectorContainer ts/perspectives/tests/TestsTemplate.soy:10"' : '') + '></div>');
};
export { $selectorContainer as selectorContainer };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $selectorContainer.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.selectorContainer';
}


/**
 * @param {!$baselineSelector.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $baselineSelector = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $baselineSelector$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.formattedBaseline);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {string} formattedBaseline
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $baselineSelector$ = function($$areYouAnInternalCaller, $ijData, formattedBaseline) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.baselineSelector']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.baselineSelector']({formattedBaseline: formattedBaseline}, $ijData);
  }
  soy.assertParamType(typeof formattedBaseline === 'string', 'formattedBaseline', formattedBaseline, '@param', 'string');
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div id="baseline-selector-container" class="item"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.baselineSelector ts/perspectives/tests/TestsTemplate.soy:19"' : '') + '><label class="item tiny ui header">Baseline:</label><a id="baseline-button" class="item" title="Only code changes that happened after this baseline are regarded in the test gap analysis"><span><i class="wait icon"></i> ' + soy.$$escapeHtml(formattedBaseline) + '</span></a></div>');
};
export { $baselineSelector as baselineSelector };
export { $baselineSelector$ as baselineSelector$ };
/**
 * @typedef {{
 *  formattedBaseline: string,
 * }}
 */
$baselineSelector.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $baselineSelector.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.baselineSelector';
}


/**
 * @param {!$testGapTreeMapBaselineAndRatioInfo.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $testGapTreeMapBaselineAndRatioInfo = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $testGapTreeMapBaselineAndRatioInfo$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.testGap, opt_data.testedMethods, opt_data.untestedMethods, opt_data.baselineEndDateInformation);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {number} testGap
 * @param {number} testedMethods
 * @param {number} untestedMethods
 * @param {{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}} baselineEndDateInformation
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $testGapTreeMapBaselineAndRatioInfo$ = function($$areYouAnInternalCaller, $ijData, testGap, testedMethods, untestedMethods, baselineEndDateInformation) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testGapTreeMapBaselineAndRatioInfo']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testGapTreeMapBaselineAndRatioInfo']({testGap: testGap, testedMethods: testedMethods, untestedMethods: untestedMethods, baselineEndDateInformation: baselineEndDateInformation}, $ijData);
  }
  soy.assertParamType(typeof testGap === 'number', 'testGap', testGap, '@param', 'number');
  soy.assertParamType(typeof testedMethods === 'number', 'testedMethods', testedMethods, '@param', 'number');
  soy.assertParamType(typeof untestedMethods === 'number', 'untestedMethods', untestedMethods, '@param', 'number');
  soy.assertParamType(google.isObject(baselineEndDateInformation), 'baselineEndDateInformation', baselineEndDateInformation, '@param', '{baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,}');
  return soy.VERY_UNSAFE.ordainSanitizedHtml($soy$ts$commons$UIUtilsTemplate.baselineEndDateInformation$(soy.$$internalCallMarkerDoNotUse, $ijData, baselineEndDateInformation) + ' | <abbr' + soy.$$whitespaceHtmlAttributes($soy$ts$commons$UIUtilsTemplate.tooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, '' + (testedMethods + untestedMethods != 1 ? '' + untestedMethods + ' of the ' + '' + (testedMethods + untestedMethods) + ' changed/added methods since baseline were not tested after their latest change' : '1 changed/added method since baseline was ' + (untestedMethods == 1 ? 'not' : '') + ' tested after its latest change'))) + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.testGapTreeMapBaselineAndRatioInfo ts/perspectives/tests/TestsTemplate.soy:47"' : '') + '>Test Gap: ' + soy.$$escapeHtml(soy.$$round(testGap * 100, 0)) + '%</abbr>');
};
export { $testGapTreeMapBaselineAndRatioInfo as testGapTreeMapBaselineAndRatioInfo };
export { $testGapTreeMapBaselineAndRatioInfo$ as testGapTreeMapBaselineAndRatioInfo$ };
/**
 * @typedef {{
 *  testGap: number,
 *  testedMethods: number,
 *  untestedMethods: number,
 *  baselineEndDateInformation: {baselineShortText: string, endShortText: string, baselineDescription: string, endDescription: string,},
 * }}
 */
$testGapTreeMapBaselineAndRatioInfo.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testGapTreeMapBaselineAndRatioInfo.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.testGapTreeMapBaselineAndRatioInfo';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $testDetailsView = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testDetailsView']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testDetailsView'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui centered container segment max80" id="test-location"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.testDetailsView ts/perspectives/tests/TestsTemplate.soy:62"' : '') + '><div class="ts-test-row" id="test-info-row"><div id="test-tab-container"></div></div></div>');
};
export { $testDetailsView as testDetailsView };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testDetailsView.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.testDetailsView';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $testCodeTemplate = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testCodeTemplate']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.testCodeTemplate'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="test-code-breadcrumb-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.testCodeTemplate ts/perspectives/tests/TestsTemplate.soy:70"' : '') + '></div><div class="ui invisible divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.testCodeTemplate ts/perspectives/tests/TestsTemplate.soy:71"' : '') + '></div><div class="code-container" id="code-container"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.testCodeTemplate ts/perspectives/tests/TestsTemplate.soy:72"' : '') + '></div>');
};
export { $testCodeTemplate as testCodeTemplate };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $testCodeTemplate.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.testCodeTemplate';
}


/**
 * @param {?Object<string, *>=} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $noTestCodeTemplate = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  if (false && soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.noTestCodeTemplate']) {
    return soy.$$stubsMap['ts.perspectives.tests.TestsTemplate.noTestCodeTemplate'](opt_data, $ijData);
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml('<div class="ui invisible divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.noTestCodeTemplate ts/perspectives/tests/TestsTemplate.soy:76"' : '') + '></div><div class="ui message"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.perspectives.tests.TestsTemplate.noTestCodeTemplate ts/perspectives/tests/TestsTemplate.soy:77"' : '') + '><p>Teamscale could not find the source code for this test! Please refer to the <a href="documentation/reference/supported-technologies/test-frameworks/#test-implementations">documentation</a> for further details.</p></div>');
};
export { $noTestCodeTemplate as noTestCodeTemplate };
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $noTestCodeTemplate.soyTemplateName = 'ts.perspectives.tests.TestsTemplate.noTestCodeTemplate';
}
